import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "home",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/"
  },
  // {
  //   id: "demo",
  //   title: "Demo",
  //   type: "item",
  //   icon: <Icon.Monitor size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/demo",
  //   disabled: true
  // },
  {
    id: "billing",
    title: "Billing",
    type: "item",
    icon: <Icon.ShoppingBag size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/billing",
    // disabled: true
  },
  // {
  //   id: "support",
  //   title: "Support",
  //   type: "item",
  //   icon: <Icon.Headphones size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/contrast",
  //   // disabled: true
  // },
  {
    id: "faq",
    title: "FAQ",
    type: "item",
    icon: <Icon.BookOpen size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/faq",
    // disabled: true
  }
]

export default horizontalMenuConfig
